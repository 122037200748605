import React from "react";
import "animate.css/animate.min.css";
import { Grid } from "@material-ui/core";

export default function privacy() {

    const styles = {
        container: {
          fontFamily: "'Arial', sans-serif",
          lineHeight: "1.6",
          padding: "20px",
          color: "#333",
          borderRadius: "8px",
          marginTop:100
        },
        title: {
          fontSize: "28px",
          fontWeight: "bold",
          marginBottom: "10px",
          color: "#222",
        },
        sectionTitle: {
          fontSize: "22px",
          fontWeight: "bold",
          marginTop: "20px",
          marginBottom: "10px",
          color: "#555",
        },
        subSectionTitle: {
          fontSize: "18px",
          fontWeight: "bold",
          marginTop: "15px",
          color: "#444",
        },
        paragraph: {
          fontSize: "16px",
          marginBottom: "15px",
        },
        list: {
          paddingLeft: "20px",
          marginBottom: "15px",
        },
        listItem: {
          marginBottom: "10px",
        },
        contact: {
          marginTop: "20px",
          fontSize: "16px",
        },
      };

    return (
        <div>
            <div className="background-dots-one" style={{ width: '70%' }} />
            <Grid
                container
                style={{ padding: "30px", paddingTop: "80px", paddingBottom: "80px" }}
            >
                <Grid item md={12} style={{ paddingTop: "40px" }}>
                    <div
                        style={{
                            display: "flex",
                            paddingTop: "80px",
                            alignItems: "center",
                            width: "60vw",
                            backgroundColor: "transparent",
                            paddingBottom: "0px"
                        }}
                    >
                        <div style={{ zIndex: "999" }}>
                            <p className="heading">
                            Privacy Policy
                            </p>
                        </div>
                    </div>
                </Grid>


                <Grid item md={12} style={{ padding: "0px" }}>
                <div style={styles.container}>
      <p style={styles.paragraph}>
        Effective Date: 2024 November 20
      </p>
      <p style={styles.paragraph}>
        Welcome to Viewbox (ViewFI Interactive Private Limited) 
       
        Your privacy is important to us.
        <br />
         This Privacy Policy explains how we collect, use, disclose, and safeguard your information  <br />when you use our digital signage platform, website, or related services.
      </p>
      <p style={styles.paragraph}>
        By using our services, you agree to the terms outlined in this Privacy Policy.
      </p>

      <div style={styles.sectionTitle}>1. Information We Collect</div>
      <p style={styles.subSectionTitle}>A. Personal Information</p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <b>Account Registration:</b> Name, email address, phone number, company name, and payment information.
        </li>
        <li style={styles.listItem}>
          <b>Customer Support:</b> Information you provide when contacting our support team.
        </li>
      </ul>
      <p style={styles.subSectionTitle}>B. Non-Personal Information</p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <b>Usage Data:</b> Information about your interactions with our platform, such as device information, IP addresses, browser types, and usage statistics.
        </li>
        <li style={styles.listItem}>
          <b>Cookies:</b> Small data files stored on your device to enhance user experience.
        </li>
      </ul>

      <div style={styles.sectionTitle}>2. How We Use Your Information</div>
      <ul style={styles.list}>
        <li style={styles.listItem}>To provide and manage our services.</li>
        <li style={styles.listItem}>To communicate updates, promotions, or important notices.</li>
        <li style={styles.listItem}>To analyze usage trends and improve platform performance.</li>
        <li style={styles.listItem}>To comply with legal obligations.</li>
      </ul>

      <div style={styles.sectionTitle}>3. Sharing Your Information</div>
      <p style={styles.paragraph}>
        We do not sell your personal information. However, we may share it under these circumstances:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <b>With Service Providers:</b> To perform functions like payment processing or data hosting.
        </li>
        <li style={styles.listItem}>
          <b>With Legal Authorities:</b> If required to comply with legal obligations.
        </li>
        <li style={styles.listItem}>
          <b>With Business Partners:</b> When you explicitly agree to share your data.
        </li>
      </ul>

      <div style={styles.sectionTitle}>4. Data Security</div>
      <p style={styles.paragraph}>
        We implement robust security measures to protect your data, including encryption, secure servers, and access controls. However, no method of data transmission or storage is entirely secure.
      </p>

      <div style={styles.sectionTitle}>5. Data Retention</div>
      <p style={styles.paragraph}>
        We retain your information as long as necessary to provide our services or comply with legal requirements. You can request data deletion by contacting us.
      </p>

      <div style={styles.sectionTitle}>6. Your Rights</div>
      <p style={styles.paragraph}>
        Depending on your jurisdiction, you may have the following rights:
      </p>
      <ul style={styles.list}>
        <li style={styles.listItem}>Access, correct, or delete your personal data.</li>
        <li style={styles.listItem}>Object to data processing or withdraw consent.</li>
        <li style={styles.listItem}>
          File a complaint with a data protection authority.
        </li>
      </ul>

      <div style={styles.sectionTitle}>7. Cookies and Tracking</div>
      <p style={styles.paragraph}>
        We use cookies to personalize your experience and analyze traffic. You can manage your cookie preferences through your browser settings.
      </p>

      <div style={styles.sectionTitle}>8. Third-Party Links</div>
      <p style={styles.paragraph}>
        Our platform may include links to third-party websites. We are not responsible for their privacy practices or content.
      </p>

      <div style={styles.sectionTitle}>9. Updates to This Policy</div>
      <p style={styles.paragraph}>
        We may update this Privacy Policy periodically. Changes will be posted on this page, and the "Effective Date" will reflect the most recent update.
      </p>

      <div style={styles.sectionTitle}>10. Contact Us</div>
      <p style={styles.paragraph}>
        For questions or concerns about this Privacy Policy, contact us:
      </p>
      <p style={styles.contact}>
        Email: info@viewbox.com <br />
        Phone: +91 9895166334
      </p>

      <p style={styles.paragraph}>

      By using our platform, you acknowledge that you have read, understood, and agreed to this Privacy Policy.
      </p>
    </div>
                </Grid>
            </Grid>

        </div>
    )
}
