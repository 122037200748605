import React from "react";
import Grid from "@material-ui/core/Grid";
import "./payment.css";
import Enterprise from "./plans/enterprise";
import Offer from "./Offer";
import Info from './info'
import Basic from './plans/basic'
import Premium from './plans/premium'
import Standard from './plans/standard'
import "animate.css/animate.min.css";

export default function landing() {
  return (
    <Grid
      container
      style={{ padding: "30px", paddingTop: "80px", paddingBottom: "80px" }}
    >
      <Grid item md={12} style={{ paddingTop: "40px" }}>
        <div
          style={{
            display: "flex",
            padding: "40px",
            alignItems: "center",
            width: "60vw",
            backgroundColor: "transparent",
            paddingBottom: "0px"
            // backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb9cb' fill-opacity='0.41' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='2'/%3E%3Ccircle cx='13' cy='50' r='3'/%3E%3C/g%3E%3C/svg%3E")`
          }}
        >
          <div style={{ zIndex: "999" }}>
            <p className="heading">
             Contact
            </p>
          </div>
        </div>
      </Grid>

      
      
      <Grid item md={12} style={{ padding: "2px 40px", marginBottom:'600px' }}>
         <div style={{padding:'20px 0px'}}>
        <p className="body" style={{padding:'5px 0px'}}>Email: info@viewbox.com </p>
        <p className="body" style={{padding:'5px 0px'}}>Phone: +91 9895166334</p>
        
        
            
           </div>

      </Grid>
         

    </Grid>
  );
}
