import React from "react";
import "animate.css/animate.min.css";
import { Grid } from "@material-ui/core";

export default function termAndConditions() {

    const styles = {
        container: {
          fontFamily: "'Arial', sans-serif",
          lineHeight: "1.6",
          padding: "20px",
          color: "#333",
      
        
          borderRadius: "8px",
        },
        title: {
          fontSize: "28px",
          fontWeight: "bold",
          marginBottom: "10px",
          color: "#222",
        },
        sectionTitle: {
          fontSize: "22px",
          fontWeight: "bold",
          marginTop: "20px",
          marginBottom: "10px",
          color: "#555",
        },
        paragraph: {
          fontSize: "16px",
          marginBottom: "15px",
        },
        list: {
          paddingLeft: "20px",
          marginBottom: "15px",
        },
        listItem: {
          marginBottom: "10px",
        },
        contact: {
          marginTop: "20px",
          fontSize: "16px",
        },
      };

    return (
        <div>
            <div className="background-dots-one" style={{ width: '70%' }} />
            <Grid
                container
                style={{ padding: "30px", paddingTop: "80px", paddingBottom: "80px" }}
            >
                <Grid item md={12} style={{ paddingTop: "40px" }}>
                    <div
                        style={{
                            display: "flex",
                            paddingTop: "80px",
                            alignItems: "center",
                            width: "60vw",
                            backgroundColor: "transparent",
                            paddingBottom: "0px"
                        }}
                    >
                        <div style={{ zIndex: "999" }}>
                            <p className="heading">
                                Terms and Conditions
                            </p>
                        </div>
                    </div>
                </Grid>


                <Grid item md={12} style={{ padding: "0px" }}>
                <div style={styles.container}>
      <div style={styles.title}>Terms and Conditions</div>
      <p style={styles.paragraph}>
      Effective Date: 2024 November 20
      </p>
      <p style={styles.paragraph}>
        Welcome to Viewbox (ViewFI Interactive Private Limited )! These Terms and Conditions ("Terms") govern your use of our digital signage platform, including our website dashboard and Android application ("Services"). By using our Services, you agree to be bound by these Terms.
      </p>

      <div style={styles.sectionTitle}>1. Use of Services</div>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          You must be at least 18 years old or the age of majority in your jurisdiction to use our Services.
        </li>
        <li style={styles.listItem}>
          You agree to use the Services only for lawful purposes and in compliance with all applicable laws and regulations.
        </li>
        <li style={styles.listItem}>
          Unauthorized access, reverse engineering, or misuse of the Services is strictly prohibited.
        </li>
      </ul>

      <div style={styles.sectionTitle}>2. Account Responsibilities</div>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          You are responsible for maintaining the confidentiality of your account credentials.
        </li>
        <li style={styles.listItem}>
          Any activity performed under your account is your responsibility. Notify us immediately of unauthorized use.
        </li>
        <li style={styles.listItem}>
          You must provide accurate and up-to-date information during registration and usage.
        </li>
      </ul>

      <div style={styles.sectionTitle}>3. Intellectual Property</div>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          All content, trademarks, and materials on the Services are the intellectual property of Viewbox (ViewFI Interactive Private Limited) or its licensors.
        </li>
        <li style={styles.listItem}>
          You may not reproduce, distribute, or modify any part of the Services without prior written consent.
        </li>
      </ul>

      <div style={styles.sectionTitle}>4. Payments and Subscriptions</div>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          Some features of the Services may require payment or a subscription. You agree to pay all fees associated with your use.
        </li>
        <li style={styles.listItem}>
          We reserve the right to change our pricing structure with reasonable notice.
        </li>
        <li style={styles.listItem}>
          Failure to pay fees may result in restricted access or termination of your account.
        </li>
      </ul>

      <div style={styles.sectionTitle}>5. Limitations of Liability</div>
      <p style={styles.paragraph}>
        To the fullest extent permitted by law, Viewbox (ViewFI Interactive Private Limited) shall not be liable for any indirect, incidental, or consequential damages arising from your use of the Services. This includes, but is not limited to, loss of data, revenue, or business opportunities.
      </p>

      <div style={styles.sectionTitle}>6. Termination</div>
      <p style={styles.paragraph}>
        We reserve the right to suspend or terminate your access to the Services at any time for violating these Terms or other reasons at our discretion.
      </p>

      <div style={styles.sectionTitle}>7. Modifications to Services</div>
      <p style={styles.paragraph}>
        We may update, modify, or discontinue the Services, including specific features, at any time without notice.
      </p>

      <div style={styles.sectionTitle}>8. Governing Law</div>
      <p style={styles.paragraph}>
        These Terms are governed by the laws of India, without regard to its conflict of laws principles. Any disputes shall be resolved exclusively in the courts of Kerala, India.
      </p>

      <div style={styles.sectionTitle}>9. Contact Us</div>
      <p style={styles.paragraph}>
        For any questions about these Terms and Conditions, please contact us:
      </p>
      <p style={styles.contact}>
        Email: info@viewbox.com <br />
        Phone: +91 9895166334
      </p>
    </div>                </Grid>
            </Grid>

        </div>
    )
}
