import React, { Component } from "react";
import './signup.css'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom';


class index extends Component {

  handleSignUpClick = () => {
    console.log("his.props.history", this.props.history)
    this.props.history.push('/contact'); // Navigate to /contact
  };

  render() {
   


    return (
      <Grid container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding:'20px',
          paddingBottom:'40px',
          paddingTop:'40px',
          zIndex:'99999', marginBottom:'100px',
          height:'400px'
        }}
      >
        <div
         className="signup-background"
        >

<Grid container >
            <Grid md={6} sm={12} lg={6} xs={12} item style={{ margin:'0', padding:'0'}}> 

          


          <div
           className='signup-dot-background'
          >
              <p
               
                className="heading"
              >
                Sign up now.
              </p>
           
          </div>



          <div
            style={{
              display: "flex",
              padding: "10px 30px ",
              alignItems: "center",
              backgroundColor: "transparent",
            }}
          >
              <p
               className="title"
              >
                Join to experience the future <br />
                of customer engagement
              </p>
          </div>



          <div style={{display: "flex",
              padding: "10px 30px ",
              alignItems: "center",
              backgroundColor: "transparent",}}  >      
                 <button onClick={this.handleSignUpClick}   className="button-primary">Sign up free</button>
                 </div>
 
             
{/* 
              <Col xs={6} md={6}   style={{ margin:'0', padding:'0'}}>
              <img
            src={require("../../../assets/TVElement.png")}
          width="150%"
          />
              </Col> */}

</Grid>
             </Grid>



        </div>


{/* 
     <div
          style={{
            position:'absolute',
            left: "43%",
            top: "555%",
            marginLeft: "-10px",
            marginTop: "-10px"
          }}
        >
          <img
            src={require("../../../assets/TVElement.png")}
           width="120%"
          />
        </div>  */}


      </Grid>
    );
  }
}


export default withRouter(index);

